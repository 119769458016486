.App {
  text-align: center;
}

.App-header{
  background-color:black;
  color: white;
}

.App-header h1{
  padding: 30px;
}

.App-footer{
  background-color:black;
  color: white;
}

.App-footer h2{
  padding: 30px;
}

.Cards{
  display: grid;
  gap: 1em;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  margin: 1.5rem;
  text-align: center; 
}

.add{
  display: flex;
  justify-content: space-evenly;
}

.label{
  margin-right: 40px;
  margin-left: 10px;
  border-radius: 10px;
  border-color: black;
  border-width: 2px;
}

.input{
  border-radius: 15px;
}

.formButton{
  padding:1px 10px;
  border-radius: 10px;
  border-color: black;
  background-color: black;
  color: white;
  box-shadow: 0 3px 8px 3px rgba(0, 0, 0, 0.5);
}

.card {
  display: flex;
  flex-direction: column;
  margin: 10px;
  background-color: black;
  color: white;
  border-radius: 15px;
  box-shadow: 0 10px 20px 10px rgba(0, 0, 0, 0.5);
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-justify: center;
  margin: auto;
  width:100%;
}

.card-body h4 {
  margin: auto;
}

.card .btn{
  width:300px;
  background-color: red;
  border-color: red;
  margin: auto;
  margin-bottom:10px;
}

.card .delete{
  width:100px;
  margin: auto;
  border-radius: 10px;
}

.card .video{
  margin: auto;
  margin-top:20px;
}

/* .card .videorating{
  padding: 0 40px;
} */

.card .votes{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width:340px;
  padding:20px 0;
  margin: auto;
}

.card .up{
  width: 110px;
  background-color: green;
  border-color: green;
  border-radius: 10px;
  color:white;
  font-weight: 900;
  font-size: large;
}

.card .down{
  width:110px;
  background-color: red;
  border-color: red;
  border-radius: 10px;
  color:white;
  font-weight: 800;
  font-size: large;
}

.ratings{
  font-size: medium;
  margin-top:10px;
  margin-bottom: -10px;
}